export enum BackendError {
  USER_INVITATION_NOT_EXISTS = 1000,
  USER_NOT_EXISTS = 1001,
  USER_BAD_ACTIVATION_TOKEN = 1002,
  USER_BAD_USER_ID = 1003,
  USER_PASSWORD_RESET_TOKEN_NOT_EXISTS = 1004,
  USER_PASSWORD_RESET_TOKEN_EXPIRED = 1005,
  USER_PASSWORD_TOO_COMMON = 1006,
  USER_PASSWORD_TOO_SIMILAR = 1007,
  USER_PASSWORD_NEW_SAME_AS_OLD = 1008,
  USER_WRONG_INVITATION_EMAIL = 1009,
  USER_DOES_NOT_BELONG_TO_STUDY = 1010,
  USER_PASSWORD_WRONG = 1011,
  USER_TEMPORARILY_BLOCKED = 1012,
  USER_INVITATION_ALREADY_EXISTS = 1013,
  USER_BAD_ROLE = 1014,
  USER_LAST_ADMIN_CANNOT_CHANGE_ROLE = 1015,
  USER_LAST_ADMIN_CANNOT_DELETE = 1016,
  USER_INVALID_EMAIL_FORMAT = 1017,
  USER_EPHEMERAL_CODE_INVALID = 1018,
  USER_MFA_CODE_INVALID = 1019,
  USER_WITH_THAT_EMAIL_EXISTS = 1020,
  USER_IS_EXCLUDED_FROM_SECTION_OR_SUBSECTION = 1021,
  USER_WRONG_LOGIN_TYPE = 1022,
  USER_NEW_EMAIL_SAME_AS_OLD = 1023,
  USER_IS_NOT_SUBJECT = 1024,

  STUDY_NOT_EXISTS = 2000,
  STUDY_REFERENCE_ALREADY_EXISTS = 2001,
  STUDY_REFERENCE_WRONG_FORMAT = 2002,
  STUDY_CANNOT_UPDATE_ADMIN = 2003,
  STUDY_STATUS_ILLEGAL_TRANSITION = 2004,
  STUDY_STATUS_SUSPENDED = 2005,
  STUDY_STATUS_ENDED = 2006,
  STUDY_DOCUMENTS_LIMIT_REACHED = 2007,
  STUDY_DOCUMENT_NOT_EXISTS = 2008,
  STUDY_DOCUMENT_OWNED_BY_ANOTHER_USER = 2009,
  STUDY_CENTER_ABBREVIATION_WRONG_FORMAT = 2010,
  STUDY_CENTER_ABBREVIATION_ALREADY_EXISTS = 2011,
  STUDY_CENTERS_LIMIT_REACHED = 2012,
  STUDY_CENTER_NOT_IN_STUDY = 2013,
  STUDY_CENTER_NOT_EXISTS = 2014,
  STUDY_CENTER_CANT_DELETE_USED = 2015,
  STUDY_STATUS_NOT_LIVE = 2016,
  STUDY_STATUS_ARCHIVED = 2017,
  STUDY_STATUS_NOT_DRAFT = 2018,
  STUDY_SUBJECT_ALREADY_RANDOMISED = 2019,
  STUDY_NO_RANDOMISATION = 2020,
  STUDY_USER_EXISTS = 2021,
  STUDY_STATUS_LIVE = 2022,
  STUDY_RANDOMISATION_EXISTS = 2023,
  STUDY_UUID_HEADER_MISSING = 2024,
  STUDY_WRONG_SUBJECT_ID_PATTERN = 2025,
  STUDY_WRONG_SUBJECT_ID_TYPE = 2026,
  STUDY_MISSING_ENDED_TO_LIVE_REASON = 2027,
  STUDY_RANDOMISATION_METHOD_REQUIRED = 2028,
  STUDY_RANDOMISATION_DISTANCE_METHOD_REQUIRED = 2029,
  STUDY_CENTER_CANT_ADD_ARCHIVED = 2030,
  STUDY_RANDOMISATION_ALLOCATION_ERROR = 2031,
  STUDY_CENTER_CANT_DELETE_LAST = 2032,
  STUDY_CENTER_CODE_ALREADY_EXISTS = 2033,
  STUDY_CENTER_CODE_NOT_ALLOW_TO_MODIFY = 2034,
  STUDY_STATUS_NEITHER_DRAFT_NOR_LIVE = 2035,

  ECRF_ALREADY_EXISTS = 3000,
  ECRF_NOT_EXISTS = 3001,
  ECRF_SECTION_NOT_EXISTS = 3002,
  SUBSECTION_NOT_EXISTS = 3003,
  ECRF_QUESTION_NOT_EXISTS = 3004,
  ECRF_QUESTION_VARIABLE_NOT_UNIQUE = 3005,
  ECRF_QUESTION_VARIABLE_BAD_FORMAT = 3006,
  ECRF_QUESTION_LIMIT_REACHED = 3007,
  ECRF_SECTION_LIMIT_REACHED = 3008,
  ECRF_QUESTION_OPTION_NOT_EXISTS = 3009,
  ECRF_CANNOT_EDIT_LIVE_STUDY_ECRF = 3010,
  ECRF_SUBSECTION_LIMIT_REACHED = 3011,
  ECRF_FILE_NO_EXISTS = 3012,
  ECRF_STATIC_CONTENT_LIMIT_REACHED = 3013,
  ECRF_CANNOT_EDIT_EXCLUDED_INCLUSION = 3014,
  ECRF_STATIC_CONTENT_IMAGE_NOT_FOUND = 3015,
  ECRF_QUESTION_CANNOT_BE_REQUIRED = 3016,
  ECRF_QUESTION_INVALID_OPERATOR_ARGS = 3017,
  ECRF_QUESTION_INVALID_DATETIME_SUBTYPE = 3018,
  ECRF_QUESTION_INVALID_TYPE = 3019,
  ECRF_REPEATED_MEASURE_LIMIT_REACHED = 3020,
  ECRF_BLOCKS_IN_REPEATED_MEASURE_LIMIT_REACHED = 3021,
  ECRF_QUESTION_CONFIG_MAX_NOT_GREATER_THAN_MIN = 3022,
  ECRF_QUESTION_CONFIG_TOO_FEW_OPTIONS = 3023,
  ECRF_QUESTION_VARIABLE_NOT_EXISTS = 3024,
  ECRF_BUTTON_CONTENT_LIMIT_REACHED = 3025,
  ECRF_BUTTON_CONTENT_NOT_FOUND = 3026,
  ECRF_QUESTION_USED_IN_STRATIFICATION = 3027,
  ECRF_SECTION_HIDDEN = 3028,
  ECRF_SUBSECTION_LOCKED = 3029,
  ECRF_DATA_VALIDATION_LIMIT_REACHED = 3030,
  ECRF_ROW_ALREADY_ANSWERED = 3031,

  ANSWER_BAD_SELECTIONS = 4000,
  ANSWER_BAD_DATETIME_FORMAT = 4001,
  ANSWER_NOT_EXISTS = 4002,
  ANSWER_FOR_INVALID_QUESTION_TYPE = 4003,
  ANSWER_INVALID_VALUE = 4004,
  ANSWER_IS_PREFILLED = 4005,
  ANSWER_TOO_MANY_CHARACTERS = 4006,
  ANSWER_QUESTION_DOES_NOT_BELONG_TO_EDC = 4007,

  TEMPLATE_TITLE_ALREADY_EXISTS = 5001,
  TEMPLATE_NOT_EXISTS = 5002,
  TEMPLATE_DUPLICATED_SECTIONS_IDS = 5003,
  TEMPLATE_DUPLICATED_SUBSECTIONS_IDS = 5004,
  TEMPLATE_WRONG_PARAMS = 5005,
  TEMPLATE_DUPLICATED_BLOCKS_IDS = 5006,
  TEMPLATE_INVALID_STRUCTURE = 5007,

  INCLUSION_NOT_EXISTS = 6000,
  INCLUSION_ALREADY_EXISTS = 6001,

  SUBJECT_NOT_EXISTS = 7000,
  SUBJECT_ALREADY_EXISTS = 7001,
  SUBJECT_BAD_FORMAT = 7002,
  SUBJECT_ALREADY_IN_THE_STUDY = 7003,
  SUBJECT_NOT_IN_THE_STUDY = 7004,
  SUBJECT_EMAIL_ALREADY_TAKEN = 7005,
  SUBJECT_CANT_EDIT_EMAIL = 7006,
  SUBJECT_NOT_CONSENTED = 7007,
  SUBJECT_CANT_BE_DELETED = 7008,
  SUBJECT_CANT_ASSIGN_TO_STRATUM = 7009,
  SUBJECT_ID_NOT_UNIQUE = 7010,
  SUBJECT_SHARING_FORBIDDEN = 7011,
  SUBJECT_NOT_AVAILABLE = 7012,
  SUBJECT_CONSENT_MAX_LIMIT = 7013,
  SUBJECT_ACCESS_ONLY = 7014,

  EXPORT_NO_INCLUSIONS = 8000,
  EXPORT_PDF_INCLUSION_ID_NOT_PROVIDED = 8001,
  EXPORT_PDF_TYPE_NOT_ALLOWED = 8002,
  EXPORT_PDF_STUDY_ID_NOT_PROVIDED = 8003,
  EXPORT_PDF_EXPORTING_UPLOADED_FILES_NOT_ALLOWED = 8004,
  EXPORT_ACCESS_NOT_ALLOWED = 8005,

  UPLOADED_FILE_RELATED_OBJECT_NOT_EXISTS = 9000,
  UPLOADED_FILE_FILES_PER_OBJECT_LIMIT_REACHED = 9001,
  UPLOADED_FILE_SIZE_LIMIT_EXCEEDED = 9002,
  UPLOADED_FILE_QUESTION_NOT_EXISTS = 9003,
  UPLOADED_FILE_INCLUSION_NOT_EXISTS = 9005,
  UPLOADED_FILE_INCLUSION_AND_QUESTION_IN_DIFFERENT_STUDIES = 9006,
  UPLOADED_FILE_INVALID_FORMAT = 9007,
  UPLOADED_FILE_INVALID_IMG_FORMAT = 9008,
  UPLOADED_FILE_ENCODING_NOT_DETECTED = 9009,
  UPLOADED_FILE_WRONG_RESOURCE_TYPE = 9010,

  QUERY_ALREADY_EXISTS = 10000,
  QUERY_BAD_STATUS_TRANSITION = 10001,
  QUERY_WRONG_PARAMS = 10002,
  QUERY_REPLIES_LIMIT_REACHED = 10003,
  QUERY_NOT_EXISTS = 10004,
  QUERY_STATUS_CLOSED = 10005,

  CONDITIONAL_LOGIC_BAD_QUESTION_TYPE = 11000,
  CONDITIONAL_LOGIC_SELF_REFERENCE_ERROR = 11001,
  CONDITIONAL_LOGIC_BAD_OPTION = 11002,
  CONDITIONAL_LOGIC_BAD_JOIN_OPERATOR = 11003,
  CONDITIONAL_LOGIC_MISSING_REFERENCE = 11004,
  CONDITIONAL_LOGIC_TYPE_CHANGE_NOT_ALLOWED = 11005,
  CONDITIONAL_LOGIC_BAD_BETWEEN_WRONG_LIST = 11006,
  CONDITIONAL_LOGIC_BAD_BETWEEN_WRONG_ELEMENTS_TYPE = 11007,
  CONDITIONAL_LOGIC_BAD_BETWEEN_WRONG_VALUE_ORDER = 11008,

  SIGNATURE_WRONG_SECTION_IDS = 12000,
  SIGNATURE_CANNOT_SIGN_SECTION = 12001,
  SIGNATURE_CANNOT_UNSIGN_SECTION = 12002,
  SIGNATURE_SECTION_ALREADY_SIGNED = 12003,

  CALCUL_EMPTY_FORMULA = 13000,
  CALCUL_INCORRECT_FORMULA_FORMAT = 13001,
  CALCUL_EMPTY_VARIABLES_IN_FORMULA = 13002,
  CALCUL_VARIABLE_MISMATCH = 13003,
  CALCUL_VARIABLE_NO_ANSWER = 13004,
  CALCUL_INCORRECT_ANSWER_FORMAT_FOR_VARIABLE = 13005,
  CALCUL_ANSWER_IS_NOT_NUMBER_TYPE = 13006,
  CALCUL_INCORRECT_COMPILED_FORMULA_FORMAT = 13007,
  CALCUL_ERROR_IN_EVALUATION = 13008,
  CALCUL_RESULT_NOT_NUMBER = 13009,
  CALCUL_NUMBER_TOO_BIG = 13010,
  CALCUL_INVALID_SUBTYPE = 13011,
  CALCUL_INVALID_REFERENCE_QUESTION = 13012,
  CALCUL_INVALID_DATE_PERIOD_TYPE = 13013,
  CALCUL_MISSING_DATE_PERIOD_AMOUNT = 13014,
  CALCUL_REFERENCE_NO_ANSWER = 13015,
  CALCUL_AGE_INVALID_QUESTION_SUBTYPE = 13016,
  CALCUL_AS_BLOCK_TEMPLATE_NOT_ALLOWED = 13017,

  REPEATED_MEASURE_BAD_BLOCK = 14000,
  REPEATED_MEASURE_NOT_FOUND = 14001,
  REPEATED_MEASURE_SINGLE_MEASURE_LIMIT_REACHED = 14002,
  REPEATED_MEASURE_QUESTION_TYPE_NOT_ALLOWED = 14003,
  REPEATED_MEASURE_QUESTION_TYPES_NOT_DEFINED = 14004,

  EPRO_NOT_EXISTS = 15000,
  EPRO_FORM_ALREADY_EXISTS = 15001,
  EPRO_FORM_NOT_EXISTS = 15002,
  EPRO_MODULE_FORBIDDEN = 15003,
  EPRO_RECORD_NOT_EXISTS = 15006,
  EPRO_STATUS_TRANSITION_NOT_ALLOWED = 15007,
  EPRO_STATUS_PUBLISHED = 15008,
  EPRO_QUESTION_NOT_EXISTS = 15009,
  EPRO_STATUS_ENDED = 15010,
  EPRO_INVALID_TARGET_DATE = 15011,
  EPRO_REMINDER_INVALID_DATE = 15012,
  EPRO_REMINDER_NO_TARGET_DATE = 15013,
  EPRO_REMINDER_MAX_AMOUNT_EXCEEDED = 15014,
  EPRO_STATUS_ARCHIVED = 15015,
  EPRO_QR_ON = 15016,
  EPRO_QR_OFF = 15017,
  EPRO_QR_EMAIL_REQUIRED = 15018,
  EPRO_SUBJECT_HAS_OTHER_CENTER = 15019,
  EPRO_RECORD_EXISTS = 15020,
  EPRO_OCCURENCES_LIMIT_REACHED = 15021,

  REMINDER_QUESTIONS_REFERENCE_NOT_EXIST = 16000,
  REMINDER_QUESTIONS_LIMIT_REACHED = 16001,

  ECONSULT_VIDEO_DOES_NOT_EXIST = 17000,
  ECONSULT_MODULE_FORBIDDEN = 17001,
  ECONSULT_SUBJECT_STUDY_DOES_NOT_EXIST = 17002,

  ANALYTICS_TABLE_LIMIT_REACHED = 18000,
  ANALYTICS_ENTITY_NOT_EXIST = 18001,
  ANALYTICS_CHART_LIMIT_REACHED = 18002,
  ANALYTICS_CHART_NOT_FOUND = 18003,
  ANALYTICS_QUESTIONS_DIFFERENT_DOCUMENTS = 18004,

  REVIEW_SUBSECTION_ALREADY_REVIEWED_BY_USER = 19000,
  REVIEW_ALREADY_UNREVIEWED = 19001,
  REVIEW_WRONG_USER_TO_UNREVIEW = 19002,
  REVIEWS_DISABLED = 19003,

  ECONSENT_ALREADY_EXISTS = 20000,
  ECONSENT_NOT_EXISTS = 20001,
  ECONSENT_MODULE_FORBIDDEN = 20004,
  ECONSENT_RECORD_NOT_EXISTS = 20006,
  ECONSENT_QUESTION_NOT_EXISTS = 20007,
  ECONSENT_RECORD_WRONG_STATUS = 20008,
  ECONSENT_SECTION_WRONG_SIGNATURE = 20009,
  ECONSENT_FULFILLMENT_PENDING = 20010,
  ECONSENT_SIGNATURE_WRONG_FORMAT = 20011,
  ECONSENT_WRONG_SUBJECT = 20012,
  ECONSENT_ESIGNATURE_FAILED = 20013,
  ECONSENT_ESIGNATURE_OFF = 20014,
  ECONSENT_ESIGNATURE_ON = 20015,
  ECONSENT_QR_CODE_NOT_FOUND = 20016,
  ECONSENT_QR_CODE_FOUND = 20017,

  ROLE_PREDEFINED = 21000,
  ROLE_NAME_NOT_UNIQUE = 21001,

  SUBJECT_ACCOUNT_WRONG_TYPE = 22000,
  INVITATION_MISSING_EMAIL = 22001,
  INVITATION_EXPIRED = 22002,
  SUBJECT_ACCOUNT_EXISTS = 22003,
  SUBJECT_EMAIL_REQUIRED = 22004,
  SUBJECT_NAME_REQUIRED = 22005,
  SUBJECT_HAS_NO_ACCOUNT = 22006,

  DATA_ANALYSIS_INVALID_FILE_CONTENT = 23000,

  FOLDER_NOT_EMPTY = 24000,
  FOLDER_TOO_NESTED = 24001,

  SUBJECT_REPOSITORY_MODULE_FORBIDDEN = 25000,
  SUBJECT_REPOSITORY_IMPORT_UUID_MISSING = 25001,
  SUBJECT_REPOSITORY_WRONG_STATUS = 25002,
  SUBJECT_REPOSITORY_VARIABLE_FORBIDDEN = 25003,
  SUBJECT_REPOSITORY_INVALID_STATUS_TRANSITION = 25004,
  SUBJECT_REPOSITORY_SUBJECT_FORBIDDEN = 25005,

  SDV_MEASURE_REQUIRED = 26000,

  API_NOT_ENABLED = 27001,

  RECRUITMENT_USER_DOES_NOT_BELONG_TO_STUDY = 28000,
  RECRUITMENT_MODULE_FORBIDDEN = 28001,
  RECRUITMENT_RECORD_STATUS_ILLEGAL_TRANSITION = 28002,
  RECRUITMENT_TOKEN_REQUIRED = 28003,
  RECRUITMENT_STUDY_INACTIVE = 28004,
  RECRUITMENT_STUDY_NOT_EXISTS = 28005,
  RECRUITMENT_RECORD_NOT_EXISTS = 28006,
  RECRUITMENT_PAYMENT_AMOUNT_REQUIRED = 28007,
  RECRUITMENT_ONGOING_PAYMENT_CONFIG_DISABLED = 28008,

  CALENDAR_MODULE_FORBIDDEN = 29000,
  CALENDAR_CAPACITY_EXCEEDED = 29001,
  CALENDAR_INVALID_TIME_START_AFTER_END = 29002,
  CALENDAR_INVALID_TIME_START_IN_THE_PAST = 29003,
  CALENDAR_NO_CENTER_ACCESS = 29004,
  CALENDAR_SCHEDULE_BOOKING_TYPE_REQUIRED = 29005,
  CALENDAR_AVAILABILITY_OVERLAP = 29006,
  CALENDAR_VISIT_ORDER_REQUIRED = 29007,
  CALENDAR_SLOT_CAPACITY_EXCEEDED = 29008,
  CALENDAR_SLOT_SUBJECT_ALREADY_BOOKED = 29009,
  CALENDAR_SEQUENTIAL_BOOKING_WRONG_SLOT = 29010,
  CALENDAR_MISSING_SLOT_FOR_SEQUENTIAL_BOOKING = 29011,
  CALENDAR_COORDINATOR_NOT_IN_CENTERS = 29012,
  CALENDAR_RESERVATION_TIMED_OUT = 29013,
  CALENDAR_SEQUENTIAL_BOOKING_VISIT_CAPACITY_DIFFERENCE = 29014,
  CALENDAR_SEQUENTIAL_BOOKING_VISIT_SLOT_COUNT_DIFFERENCE = 29015,
  CALENDAR_SLOTS_CAPACITY_INSUFFICIENT = 29016,
  CALENDAR_INVALID_NOT_DONE_CAUSE = 29017,
  CALENDAR_VISIT_CONFIRMATION_MIXED_VISITS = 29018,
  CALENDAR_VISIT_CONFIRMATION_WRONG_STATUS = 29019,
  CALENDAR_APPLICANT_WRONG_STATUS = 29020,
  CALENDAR_PAYMENT_NOT_ALLOWED = 29021,
  CALENDAR_SCHEDULE_NOT_PUBLISHED = 29022,

  PAYMENTS_MODULE_FORBIDDEN = 30000,
  PAYMENT_STATUS_ILLEGAL_TRANSITION = 30001,
  PAYMENT_SWIFT_INVALID = 30002,
  PAYMENT_IBAN_INVALID = 30003,
  PAYMENT_IBAN_WRONG_COUNTRY = 30004,
  PAYMENT_STATUS_NOT_PROCESSING = 30005,
  PAYMENT_CURRENCY_MISMATCH = 30006,
  PAYMENT_CENTER_MISMATCH = 30007,
  PAYMENT_CENTER_DATA_MISSING = 30008,
  PAYMENT_VALIDATION_ERROR = 30009,
  PAYMENT_STATUS_NOT_PAID = 30010,
  PAYMENT_COMMENT_REQUIRED = 30011,
  PAYMENT_MISSING_CURRENCY = 30012,
  PAYEMNT_NOT_ALLOWED_FOR_STATUS = 30013,
  PAYMENT_AMOUNT_CHANGE_NOT_ALLOWED_FOR_STATUS = 30014,

  SIDE_BY_SIDE_ILLEGAL_TRANSITION = 31000,
  SIDE_BY_SIDE_PROJECT_NOT_EXISTS = 31001,
  SIDE_BY_SIDE_SUBJECT_ALREADY_IN_PROJECT = 31002,
  SIDE_BY_SIDE_SUBJECT_WRONG_CENTER = 31003,
  SIDE_BY_SIDE_PROJECT_BUILDER_NOT_EDITABLE = 31004,
  SIDE_BY_SIDE_SECTION_NOT_IN_PROJECT = 31005,
  SIDE_BY_SIDE_USER_DOES_NOT_BELONG_TO_PROJECT = 31006,
  SIDE_BY_SIDE_TEST_ZONES_EXCEEDED = 31007,
  SIDE_BY_SIDE_PROJECT_TEST_NOT_EXISTS = 31008,
  SIDE_BY_SIDE_PROJECT_TEST_FORMULA_NOT_EXISTS = 31009,
  SIDE_BY_SIDE_PROJECT_TEST_ENDED = 31010,
  SIDE_BY_SIDE_PROJECT_TEST_NOT_FOR_BOOKING_SUBJECT = 31011,
  SIDE_BY_SIDE_PRODUCT_REQUIRED_FOR_NON_GLOBAL_QUESTION = 31012,
  SIDE_BY_SIDE_FORMULA_NOT_ASSIGNED_TO_TEST = 31013,
  SIDE_BY_SIDE_FORMULA_NOT_ASSIGNED_TO_PROJECT = 31014,

  PRODUCT_DATABASE_DUPLICATE_FORMULA = 32000,
  PRODUCT_DATABASE_FORMULA_DOES_NOT_EXIST = 32001,
  PRODUCT_DATABASE_FORMULA_HAS_TESTS = 32002,
  PRODUCT_DATABASE_WRONG_SEARCH_FIELD = 32003,

  SUBJECT_MISSING = 201000,
  BODY_TOO_LONG = 201001,
  INVITE_TOO_MANY_REQUESTS_FOR_THIS_RECORD = 201002,
  WRONG_TOKEN_TYPE = 201003,
  INVITE_SUBJECT_HAS_NO_EMAIL = 201004,
  INVITE_SUBJECT_HAS_NO_PHONE = 201005,
  INVITE_WRONG_SUBJECTS = 201006,
  INVITE_WRONG_PARAMS = 201007,
  DATETIME_MUST_BE_FUTURE = 201008,
  DATETIME_CANT_BE_FUTURE = 201009,
  VARIABLE_PREFIX_EXISTS = 201010,
  VARIABLE_SUFFIX_EXISTS = 201011,
  EXPORT_ALREADY_EXISTS = 201012,
  MISSING_FIELDS = 201013,
  WRONG_RECORD = 201014,
  WRONG_MODEL = 201015,
  WRONG_PARAMETER = 201016,
  WRONG_OBJECT_ID = 201017,
  RECORD_ALREADY_EXISTS = 201018
}

export class RequestError extends Error {
  public status: number

  public code?: BackendError

  public data?: Record<string, string>

  constructor(status: number, code?: BackendError, data?: Record<string, string | string[]>) {
    super(`Request error: ${status} ${code}`)
    this.status = status
    this.code = code
    this.data =
      data &&
      Object.entries(data).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          return { ...acc, [key]: value.join(', ') }
        }
        return { ...acc, [key]: value }
      }, {})
  }
}
