/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

export enum CalendarEventColor {
  Pink1 = 'pink-1',
  Pink2 = 'pink-2',
  Purple1 = 'purple-1',
  Purple2 = 'purple-2',
  Blue2 = 'blue-2',
  Blue12 = 'blue-12',
  Green4 = 'green-4',
  Red5 = 'red-5',
  Red3 = 'red-3',
  Orange1 = 'orange-1',
  Yellow3 = 'yellow-3',
  Yellow1 = 'yellow-1',
  Green7 = 'green-7',
  Green2 = 'green-2'
}

enum EventType {
  Event = 'EVENT'
}

export enum EventStudyType {
  edc = 'EDC',
  ecrf = 'ECRF',
  project = 'PROJECT'
}

export type RemoteEventStudy = { id: number; uuid: string; name: string; reference_number: string }
export type RemoteEventPerson = {
  id: number
  full_name: string
  first_name: string
  last_name: string
  datacapt_id: string
  email: string
  thumbnail: string
}
export interface RemoteEvent {
  id?: number
  organizer: number | RemoteEventPerson
  type: EventType
  center: number | { id: number; name: string; abbreviation: string }
  color: CalendarEventColor
  start: {
    date?: string
    datetime?: string
  }
  end: {
    date?: string
    datetime?: string
  }
  timezone: string
  recruitment_study?: string | RemoteEventStudy
  project?: string | RemoteEventStudy
  study?: string | RemoteEventStudy
  schedule?: number
  is_recurring: boolean
  is_full_day?: boolean
  is_available?: boolean
  recurring_period?: string
  title: string
  public_title: string
  attendees?: (number | RemoteEventPerson)[]
  participants?: (number | RemoteEventPerson)[]
  capacity?: number
}

export interface EventPerson {
  id: string
  fullName?: string
  datacaptId?: string
  email?: string
  thumbnail?: string
}

export interface CalendarEvent {
  organizer: EventPerson
  id?: number
  schedule: number
  title: string
  publicTitle: string
  startDate: Dayjs
  endDate: Dayjs
  isFullDay: boolean
  timezone: string
  startTime: string
  endTime: string
  capacity: number
  subjects: EventPerson[]
  attendees: EventPerson[]
  isAvailable: boolean
  study: {
    uuid: string
    name?: string
    reference?: string
    type: EventStudyType
  }

  center: {
    id: string
    name?: string
    abbreviation?: string
  }
  color: CalendarEventColor
  startFilterString: string // used for optimization
  endFilterString: string // used for optimization
}

const getTimeAndDateInTimezone = (dateTime: string, timezone: string) => {
  const date = dayjs(dateTime)
  const timezoneDate = dayjs.tz(date, timezone)
  return timezoneDate
}

const parseRemoteEventStudy = (remoteEvent: RemoteEvent) => {
  if (typeof remoteEvent.study === 'object' && remoteEvent.study !== null) {
    return {
      uuid: remoteEvent.study.uuid,
      name: remoteEvent.study.name,
      reference: remoteEvent.study.reference_number,
      type: EventStudyType.ecrf
    }
  }
  if (typeof remoteEvent.recruitment_study === 'object' && remoteEvent.recruitment_study !== null) {
    return {
      uuid: remoteEvent.recruitment_study.uuid,
      name: remoteEvent.recruitment_study.name,
      reference: remoteEvent.recruitment_study.reference_number,
      type: EventStudyType.edc
    }
  }
  if (typeof remoteEvent.project === 'object' && remoteEvent.project !== null) {
    return {
      uuid: remoteEvent.project.uuid,
      name: remoteEvent.project.name,
      reference: remoteEvent.project.reference_number,
      type: EventStudyType.project
    }
  }
  return null
}

const parseEventPersonForSave = (person: EventPerson): number =>
  typeof person === 'string' ? Number(person) : Number(person.id)
const parseRemoteEventPerson = (remotePerson: number | RemoteEventPerson): EventPerson => {
  if (!remotePerson || typeof remotePerson !== 'object') return {} as EventPerson

  return {
    id: String(remotePerson.id),
    fullName: remotePerson.first_name ? `${remotePerson.first_name} ${remotePerson.last_name}` : remotePerson.full_name,
    datacaptId: remotePerson.datacapt_id,
    email: remotePerson.email,
    thumbnail: remotePerson.thumbnail
  }
}

export const parseRemoteCalendarEvent = (remoteEvent: RemoteEvent): CalendarEvent => {
  const startDate = remoteEvent.is_full_day
    ? dayjs(remoteEvent.start.date)
    : getTimeAndDateInTimezone(remoteEvent.start?.datetime, remoteEvent.timezone)
  const endDate = remoteEvent.is_full_day
    ? dayjs(remoteEvent.end.date).subtract(1, 'day').endOf('day')
    : getTimeAndDateInTimezone(remoteEvent.end?.datetime, remoteEvent.timezone)

  return {
    organizer: parseRemoteEventPerson(remoteEvent.organizer),
    id: remoteEvent.id,
    schedule: remoteEvent.schedule,
    title: remoteEvent.title,
    publicTitle: remoteEvent.public_title,
    startDate,
    endDate,
    timezone: remoteEvent.timezone,
    startTime: startDate.format('HH:mm'),
    endTime: endDate.format('HH:mm'),
    isFullDay: remoteEvent.is_full_day,
    capacity: remoteEvent.capacity,
    subjects: remoteEvent.participants ? remoteEvent.participants.map(parseRemoteEventPerson) : [],
    attendees: remoteEvent.attendees ? remoteEvent.attendees.map(parseRemoteEventPerson) : [],
    isAvailable: remoteEvent.is_available,
    study: parseRemoteEventStudy(remoteEvent),
    center: typeof remoteEvent.center !== 'number' && {
      id: String(remoteEvent.center?.id),
      name: remoteEvent.center?.name,
      abbreviation: remoteEvent.center?.abbreviation
    },
    color: remoteEvent.color,
    startFilterString: startDate.format('YYYYMMDD'),
    endFilterString: endDate.format('YYYYMMDD')
  }
}

export const getUtcString = (date: Dayjs) => (date ? date.clone().utc().format('YYYY-MM-DD HH:mm') : undefined)

const getTimeAndDateInUTC = (date: Dayjs, time: string, timezone: string) => {
  const timezoneDate = dayjs.tz(`${date.format('YYYY-MM-DD')} ${time}`, timezone)
  return getUtcString(timezoneDate)
}

const parseCalendarEventForSave = (event: CalendarEvent): RemoteEvent => ({
  organizer: parseEventPersonForSave(event.organizer),
  type: EventType.Event,
  title: event.title,
  public_title: event.publicTitle || undefined,
  timezone: event.timezone,
  start: {
    date: event.isFullDay ? event.startDate.format('YYYY-MM-DD') : undefined,
    datetime: !event.isFullDay ? getTimeAndDateInUTC(event.startDate, event.startTime, event.timezone) : undefined
  },
  end: {
    date: event.isFullDay ? event.endDate.add(1, 'day').format('YYYY-MM-DD') : undefined,
    datetime: !event.isFullDay ? getTimeAndDateInUTC(event.endDate, event.endTime, event.timezone) : undefined
  },
  study: event.study?.type === EventStudyType.ecrf ? event.study.uuid : undefined,
  recruitment_study: event.study?.type === EventStudyType.edc ? event.study.uuid : undefined,
  project: event.study?.type === EventStudyType.project ? event.study.uuid : undefined,
  center: Number(event.center.id) || undefined,
  capacity: event.capacity,
  color: event.color,
  attendees: event.attendees.map(parseEventPersonForSave).filter(Boolean),
  participants: event.subjects.map(parseEventPersonForSave).filter(Boolean),
  is_recurring: false
})

interface CreateCalendarEventResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createCalendarEvent = (event: CalendarEvent, responseHandlers?: CreateCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.post('events', parseCalendarEventForSave(event))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const updateCalendarEvent = (event: CalendarEvent, responseHandlers?: CreateCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.put(`events/${event.id}`, parseCalendarEventForSave(event))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchCalendarEventOptions {
  startDate?: Dayjs
  endDate?: Dayjs
  search?: string
  centerIds?: string[]
}

interface FetchCalendarEventResponse {
  count: number
  next: number
  previous: number
  results: RemoteEvent[]
}

interface FetchCalendarResponseHandlers {
  onSuccess?: ({ events, allEventsCount }: { events: CalendarEvent[]; allEventsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchCalendarEvents = (
  { startDate, endDate, search, centerIds }: FetchCalendarEventOptions,
  responseHandlers?: FetchCalendarResponseHandlers
) => {
  const query = {
    ordering: ['start_datetime', 'end_datetime'],
    limit: 1000,
    search,
    end_after: getUtcString(startDate),
    start_before: getUtcString(endDate),
    center_ids: centerIds?.length ? centerIds : undefined,
    expand: 'organizer,center,recruitment_study,project,study,attendees,participants'
  }

  const { req, cancel } = fetchApi.get<FetchCalendarEventResponse>('events', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCalendarResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        events: body.results.map(parseRemoteCalendarEvent).filter(e => e.id),
        allEventsCount: body.count
      })
    }
  })

  return cancel
}

interface DeleteCalendarEventResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteCalendarEvent = ({ id }: { id: number }, responseHandlers?: DeleteCalendarEventResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`events/${id}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteCalendarEventResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
