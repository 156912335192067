/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { CalendarEvent, RemoteEvent, parseRemoteCalendarEvent } from '../calendar'
import { fetchApi } from '../fetchApi'
import { BookedVisitStatus } from './visits'

interface RemoteScheduleSlotSubject {
  datacapt_id: string
  name: string
  thumbnail?: string
  status: BookedVisitStatus
}

export interface ScheduleSlotSubject {
  datacaptId: string
  name: string
  thumbnail?: string
  status: BookedVisitStatus
}

export interface ScheduleSlot {
  id?: number
  date: Dayjs
  startTime: string
  subjects?: ScheduleSlotSubject[]
  isAvailable?: boolean
}

export interface RemoteScheduleSlot {
  id: number
  start: { datetime: string }
  participants?: RemoteScheduleSlotSubject[]
  is_available?: boolean
}

export const parseRemoteScheduleSlot: (slot: RemoteScheduleSlot) => ScheduleSlot = (slot: RemoteScheduleSlot) => {
  const date = slot.start?.datetime && dayjs(slot.start.datetime)

  return {
    id: slot.id,
    date,
    startTime: date?.format('HH:mm'),
    subjects:
      slot.participants?.map(subject => ({
        datacaptId: subject.datacapt_id,
        name: subject.name,
        thumbnail: subject.thumbnail,
        status: subject.status
      })) || [],
    isAvailable: slot.is_available
  }
}

interface FetchScheduleSlotsResponseHandlers {
  onSuccess?: (events: CalendarEvent[]) => void
  onRequestError?: (code: number) => void
}

interface FetchScheduleSlotsOptions {
  visitId: number
}

const getSlotUrl = (visitId: number) => `events/?schedule=${visitId}&limit=1000&`

export const fetchScheduleSlots = (
  { visitId }: FetchScheduleSlotsOptions,
  responseHandlers?: FetchScheduleSlotsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<{ results: RemoteEvent[] }>(getSlotUrl(visitId))

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchScheduleSlotsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteCalendarEvent))
    }
  })

  return cancel
}

interface SubjectToSlotOptions {
  visitId: number
  slotId: number
  subjectId: string
}

interface AddSubjectToSlotResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onSubjectAlreadyBooked?: () => void
  onWrongSlotInSequentialBooking?: () => void
  onMissingSlotForSequentialBooking?: () => void
}

export const addSubjectToSlot = (
  { slotId, subjectId, visitId }: SubjectToSlotOptions,
  responseHandlers: AddSubjectToSlotResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`${getSlotUrl(visitId)}/${slotId}/subjects/${subjectId}`, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddSubjectToSlotResponseHandlers>(
        {
          [BackendError.CALENDAR_SLOT_SUBJECT_ALREADY_BOOKED]: 'onSubjectAlreadyBooked',
          [BackendError.CALENDAR_SEQUENTIAL_BOOKING_WRONG_SLOT]: 'onWrongSlotInSequentialBooking',
          [BackendError.CALENDAR_MISSING_SLOT_FOR_SEQUENTIAL_BOOKING]: 'onMissingSlotForSequentialBooking'
        },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoveSubjectFromSlotResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const removeSubjectFromSlot = (
  { slotId, subjectId, visitId }: SubjectToSlotOptions,
  responseHandlers: RemoveSubjectFromSlotResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`${getSlotUrl(visitId)}/${slotId}/subjects/${subjectId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveSubjectFromSlotResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
